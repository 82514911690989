import React from 'react';
import Button from './molecules/Button';
import Text from './type/Text';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

export default function BlogCard({
  className,
  image,
  title,
  excerpt,
  url,
  date,
}) {
  //Excerpt length and dots
  if (excerpt.length > 165) {
    excerpt = excerpt.substring(0, 165);
    excerpt = excerpt + '...';
  }
  const sanExcerpt = () => {
    return {
      __html: excerpt,
    };
  };

  let featured = '';
  let imagegood  = '';
  if (image !== '') {
    if (image.node !== undefined) {
      imagegood = getImage(image.node.localFile);
    }
    featured = (
      <GatsbyImage
        image={imagegood}
        alt="blogimage"
        className="object-cover w-full h-64"
      />
    );
  } else {
    featured = <div className="w-full h-64 bg-gray-200" />;
  }

  return (
    <article
      data-sal="fade-in"
      data-sal-duration="200"
      data-sal-delay="100"
      className={`w-full ${className} relative mb-10 overflow-hidden md:mb-0`}
    >
      <div>{featured}</div>
      <div className="flex flex-col justify-between w-full h-full p-8 bg-brokenwhite">
        <div>
          <h6 className="font-bold">{date}</h6>
          <h5 className="mt-3 mb-6 font-bold text-red">{title}</h5>
          <Text className="mt-3 mb-10" text={excerpt} />
        </div>
        <Button
          className="absolute bottom-3"
          url={url}
          type={true}
          text="Lees meer"
        />
      </div>
    </article>
  );
}
