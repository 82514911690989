import React from 'react';
import Container from '../components/layouts/Container';
import Layout from '../components/layouts/Layout';
import Title from '../components/type/Title';
import BlogCard from '../components/BlogCard';
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import { BreadcrumbJsonLd } from 'gatsby-plugin-next-seo';
import Breadcrumb from '../components/molecules/Breadcrumb';
import { graphql } from 'gatsby';

export default function nieuws({ data }) {
  const content = data.wpPage;
  const blogs = data.allWpPost.nodes;

  const allJsonBreadcrumbs = [];
  const allBreadcrumbs = [];

  if (content.seo.breadcrumbs !== null) {
    content.seo.breadcrumbs.forEach((breadcrumb, index) => {
      allJsonBreadcrumbs.push({
        position: index + 1,
        name: breadcrumb.name,
        item: 'https://graafdaron.nl' + breadcrumb.item,
      });
      allBreadcrumbs.push({
        name: breadcrumb.name,
        item: breadcrumb.item,
      });
    });
  }

  return (
    <>
      {content.seo.breadcrumbs ? (
        <>
          <GatsbySeo
            title={content.seo.seoData.title}
            description={content.seo.seoData.description}
            openGraph={{
              type: 'website',
              url: 'https://graafdaron.nl/' + content.slug,
              title: content.seo.seoData.title,
              description: content.seo.seoData.description,
            }}
          />
          <BreadcrumbJsonLd itemListElements={allJsonBreadcrumbs} />
        </>
      ) : (
        ''
      )}
      <Layout small={true}>
        <Container className="py-16">
          <nav className="breadcrumb" aria-label="Breadcrumb">
            <ol className="breadcrumb__list">
              {allBreadcrumbs.map((breadcrumb) => {
                return (
                  <Breadcrumb title={breadcrumb.name} link={breadcrumb.item} />
                );
              })}
            </ol>
          </nav>
          <Title h1={true} title={content.title} />
          <section className="grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3">
            {blogs.map((blog) => {
              const blogDate = new Date(blog.date);
              const options = {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              };
              const date = blogDate.toLocaleDateString('nl-NL', options);
              return (
                <BlogCard
                  key={blog.id}
                  date={date}
                  title={blog.title}
                  image={blog.featuredImage ?? ''}
                  excerpt={blog.excerpt}
                  url={`/nieuws/${blog.slug}`}
                  className="md:mr-5"
                />
              );
            })}
          </section>
        </Container>
      </Layout>
    </>
  );
}

export const query = graphql`
  query blogQuery($slug: String) {
    wpPage(slug: { eq: $slug }) {
      title
      slug
      seo {
        seoData {
          description
          title
        }
        breadcrumbs {
          item
          name
        }
      }
    }
    allWpPost(sort: { order: DESC, fields: date }) {
      nodes {
        date
        excerpt
        title
        id
        slug
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, width: 500, quality: 80)
              }
            }
          }
        }
      }
    }
  }
`;
